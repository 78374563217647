import React from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de que React Router esté instalado

const Navbar = ({ onToggleRegister }) => {
  return (
    <nav className="navbar">
      <ul>
        <li><Link to="/">MundoChat</Link></li> {/* Cambiado a ruta */}
        <li><Link to="/about">¿Quiénes somos?</Link></li> {/* Cambiado a ruta */}
        <li><Link to="/staff">Staff</Link></li> {/* Camobiado a ruta */}
        <li><Link to="/webchat">Chat</Link></li> {/* Enlace a la política de privacidad */}
        <li><Link to="/games">Juegos</Link></li> {/* Enlace a la página de juegos */}
      </ul>
    </nav>
  );
};

export default Navbar;

