import React, { useState } from 'react';
import '../App.scss'; // Asegúrate de tener este archivo CSS

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [accepted, setAccepted] = useState(false); // Estado para saber si se aceptaron las cookies

  const handleAccept = () => {
    setIsVisible(false);
    setAccepted(true); // Marcar como aceptado
    document.cookie = "cookieConsent=true; max-age=" + 60 * 60 * 24 * 30; // 30 días
  };

  return (
    isVisible && (
      <div className="cookie-consent">
        <p>
          Este sitio utiliza cookies para mejorar tu experiencia. Al continuar, aceptas nuestra
          <a href="/privacy-policy" style={{ color: '#01EFD5', textDecoration: 'underline' }}>
            {' '}política de cookies
          </a>.
        </p>
        <button onClick={handleAccept}>Aceptar</button>
        {accepted && <p style={{ color: 'green' }}>Gracias por aceptar nuestra política de cookies!</p>}
      </div>
    )
  );
};

export default CookieConsent;
