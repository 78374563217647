import React, { useState } from 'react';
import '../App.scss'; // Importa el archivo CSS

const RockPaperScissors = () => {
  const [userChoice, setUserChoice] = useState('');
  const [computerChoice, setComputerChoice] = useState('');
  const [result, setResult] = useState('');

  const choices = ['Piedra', 'Papel', 'Tijera'];

  const playGame = (userSelection) => {
    setUserChoice(userSelection);
    const randomChoice = choices[Math.floor(Math.random() * choices.length)];
    setComputerChoice(randomChoice);
    determineWinner(userSelection, randomChoice);
  };

  const determineWinner = (user, computer) => {
    if (user === computer) {
      setResult('¡Es un empate!');
    } else if (
      (user === 'Piedra' && computer === 'Tijera') ||
      (user === 'Papel' && computer === 'Piedra') ||
      (user === 'Tijera' && computer === 'Papel')
    ) {
      setResult('¡Ganaste!');
    } else {
      setResult('Perdiste. ¡Intenta de nuevo!');
    }
  };

  return (
    <div className="rock-paper-scissors">
      <h2>Piedra, Papel o Tijera</h2>
      <div className="choices">
        {choices.map((choice) => (
          <button key={choice} onClick={() => playGame(choice)}>
            {choice}
          </button>
        ))}
      </div>
      {userChoice && <p>Tu elección: {userChoice}</p>}
      {computerChoice && <p>Elección de la computadora: {computerChoice}</p>}
      {result && <p>Resultado: {result}</p>}
    </div>
  );
};

export default RockPaperScissors;
