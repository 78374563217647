import React, { useState } from 'react';

const GuessTheNumber = () => {
  const [targetNumber, setTargetNumber] = useState(generateRandomNumber());
  const [guess, setGuess] = useState('');
  const [message, setMessage] = useState('');

  function generateRandomNumber() {
    return Math.floor(Math.random() * 100) + 1; // Número entre 1 y 100
  }

  const handleGuess = () => {
    const parsedGuess = parseInt(guess, 10);
    
    if (isNaN(parsedGuess)) {
      setMessage('Por favor, ingresa un número válido.');
      return;
    }

    if (parsedGuess < targetNumber) {
      setMessage('Demasiado bajo! Intenta de nuevo.');
    } else if (parsedGuess > targetNumber) {
      setMessage('Demasiado alto! Intenta de nuevo.');
    } else {
      setMessage(`¡Felicidades! Adivinaste el número: ${targetNumber}`);
    }

    setGuess(''); // Limpiar el campo de entrada
  };

  const handleInputChange = (event) => {
    setGuess(event.target.value);
  };

  const handleRestart = () => {
    setTargetNumber(generateRandomNumber());
    setMessage('');
    setGuess('');
  };

  return (
    <div className="guess-the-number">
      <h1>Adivina el Número</h1>
      <p>Estoy pensando en un número entre 1 y 100.</p>
      <input
        type="number"
        value={guess}
        onChange={handleInputChange}
        placeholder="Ingresa tu adivinanza"
      />
      <button onClick={handleGuess}>Adivinar</button>
      <button onClick={handleRestart}>Reiniciar Juego</button>
      <p>{message}</p>
    </div>
  );
};

export default GuessTheNumber;
