import React, { useState } from 'react';
import '../App.scss'; // Asegúrate de importar el archivo CSS

const Home = () => {
  const [fadeOut, setFadeOut] = useState(false); // Estado para manejar el efecto de desvanecimiento

  const handleEnterClick = () => {
    setFadeOut(true); // Activa el desvanecimiento
    setTimeout(() => {
      // Aquí puedes redirigir a la página de inicio o cambiar el estado para mostrar contenido
      console.log("Redirigiendo a la página de inicio..."); // Cambia esto por tu lógica de redirección
    }, 3000); // Duración del efecto de desvanecimiento
  };

  return (
    <div className="home">
      <img src="/images/fondohome.webp" alt="Fondo Home" className={`bg-image ${fadeOut ? 'fade-out' : ''}`} />
      <h1 className="home-title">Bienvenido a MundoChat</h1>
      <p className="home-description">Conéctate con amigos y disfruta de la conversación.</p>
      <button className="enter-button" onClick={handleEnterClick}>Entrar</button>
    </div>
  );
};

export default Home;
