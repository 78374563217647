import React, { useState, useEffect }  from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importar Routes
import './App.scss'; // Asegúrate de que el archivo CSS esté presente
import Home from './components/Home'; // Importa el componente Home
import Footer from './components/Footer'; // Importa el footer
//import PrivacyPolicy from './components/PrivacyPolicy'; // Importa el componente de Política de Privacidad
import Navbar from './components/Navbar'; // Asegúrate de importar el Navbar
import About from './components/About'; // Importa el componente About
import Staff from './components/Staff'; // Importa el componente Staff
import WebChat from './components/WebChat'; // Asegúrate de importar el componente
import Cookies from 'js-cookie'; // Importar la biblioteca de cookies
import Games from './components/Games'; // Asegúrate de que la ruta es correcta

import CookieConsent from './components/CookieConsent';


function App() {
  const [fadeOut, setFadeOut] = useState(false);
  const [showMainPage, setShowMainPage] = useState(false);
  const [audio] = useState(new Audio('/mundochat.mp3'));

  const handleAudioPlay = () => {
    audio.play().catch((error) => {
      console.log('Error al intentar reproducir el audio:', error);
    });
  };

  const handleEnterClick = () => {
    handleAudioPlay();
    setFadeOut(true);
    setShowMainPage(true);
    Cookies.set('hasVisited', 'true', { expires: 7 }); // Establecer una cookie que expire en 7 días
  };

  useEffect(() => {
    // Verificar si el usuario ya ha visitado la página
    const hasVisited = Cookies.get('hasVisited');
    if (hasVisited) {
      console.log('Bienvenido de nuevo!'); // Aquí puedes manejar la lógica para usuarios recurrentes
    } else {
      console.log('Bienvenido a la primera visita!'); // Primera visita
    }
  }, []);

  return (
    <Router>
      <div className="full-screen">
        {!showMainPage ? (
          <>
            <img 
              src="/mundochat.jpg" 
              alt="Fondo" 
              className={`background-image ${fadeOut ? 'fade-out' : ''}`} 
            />
            <button 
              className={`enter-button ${fadeOut ? 'fade-out' : ''}`} 
              onClick={handleEnterClick}
            >
              Entrar
            </button>
          </>
        ) : (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/staff" element={<Staff />} />
              <Route path="/WebChat" element={<WebChat />} />
              <Route path="/games" element={<Games />} /> {/* Nueva ruta para la página de juegos */}
              
            </Routes>
            <Footer />
          </>
        )}
        <CookieConsent />
      </div>
    </Router>
  );
}

export default App;
