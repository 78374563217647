import React from 'react';
import '../App.scss'; // Asegúrate de importar el archivo Sass

const Staff = () => {
  const staffMembers = [
    { name: 'JeFe', description: 'Desarrollador Frontend, Gerente de Proyecto, Administrador de MundoChat' },
  ];

  return (
    <div className="staff-container">
      <h1>Nuestro Staff</h1>
      <p>Conoce a nuestro increíble equipo</p>
      <div className="staff-list">
        {staffMembers.map((member, index) => (
          <div key={index} className="staff-member">
            <h3>{member.name}</h3>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Staff;
