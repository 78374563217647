import React, { useState } from 'react';
import GuessTheNumber from './GuessThenumber'; // Importa el juego de Adivina el Número
import RockPaperScissors from './RockPaperScissors'; // Importa el juego de Piedra, Papel o Tijera
import Mastermind from './Mastermind'; // Importa el nuevo juego de Mastermind

const Games = () => {
  const [selectedGame, setSelectedGame] = useState(null);

  const handleGameSelect = (game) => {
    setSelectedGame(game);
  };

  const handleBack = () => {
    setSelectedGame(null);
  };

  return (
    <div className="games-container">
      <h1>Página de Juegos</h1>
      {selectedGame === 'GuessTheNumber' ? (
        <GuessTheNumber />
      ) : selectedGame === 'RockPaperScissors' ? (
        <RockPaperScissors />
      ) : selectedGame === 'Mastermind' ? (
        <Mastermind /> // Mostrar el juego de Mastermind
      ) : (
        <ul className="games-list">
          <li>
            <button onClick={() => handleGameSelect('GuessTheNumber')}>Jugar Adivina el Número</button>
          </li>
          <li>
            <button onClick={() => handleGameSelect('RockPaperScissors')}>Jugar Piedra, Papel o Tijera</button>
          </li>
          <li>
            <button onClick={() => handleGameSelect('Mastermind')}>Jugar Mastermind</button>
          </li>
        </ul>
      )}
      {selectedGame && <button onClick={handleBack}>Volver a la lista de juegos</button>}
    </div>
  );
};

export default Games;
