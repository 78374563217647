import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <p>MundoChat@2024</p>
    </footer>
  );
};

export default Footer; // Asegúrate de que se exporta correctamente
