import React, { useState, useEffect, useCallback } from 'react'; // Asegúrate de importar useCallback
import '../App.scss'; // Importa el archivo CSS

const colors = ['🔴', '🟡', '🟢', '🔵', '🟣', '⚫']; // Colores disponibles

const Mastermind = () => {
  const [code, setCode] = useState([]); // Inicializa como un array vacío
  const [guess, setGuess] = useState(Array(4).fill(''));
  const [message, setMessage] = useState('');
  const [attempts, setAttempts] = useState(0);
  const maxAttempts = 10; // Máximo de intentos permitidos

  // Use useCallback para evitar redefinir la función en cada render
  const resetGame = useCallback(() => {
    setCode(generateCode());
    setAttempts(0);
    setGuess(Array(4).fill(''));
    setMessage('');
  }, []);

  useEffect(() => {
    resetGame(); // Genera un nuevo código al iniciar el componente
  }, [resetGame]); // Añadir resetGame a las dependencias

  function generateCode() {
    return Array.from({ length: 4 }, () => colors[Math.floor(Math.random() * colors.length)]);
  }

  const handleGuessChange = (index, value) => {
    const newGuess = [...guess];
    newGuess[index] = value;
    setGuess(newGuess);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAttempts(attempts + 1);

    const correctColors = guess.filter((color) => code.includes(color)).length;
    const correctPositions = guess.filter((color, index) => color === code[index]).length;

    if (correctPositions === 4) {
      setMessage('¡Felicidades! ¡Has adivinado el código!');
    } else if (attempts + 1 === maxAttempts) {
      setMessage(`¡Se han agotado los intentos! El código era: ${code.join(', ')}`);
    } else {
      setMessage(`Colores correctos: ${correctColors}, Posiciones correctas: ${correctPositions}`);
    }

    setGuess(Array(4).fill('')); // Reiniciar la suposición
  };

  return (
    <div className="mastermind">
      <h2>Mastermind</h2>
      <p>Adivina el código de colores en 10 intentos o menos.</p>
      <form onSubmit={handleSubmit}>
        <div className="guess-inputs">
          {guess.map((color, index) => (
            <select key={index} value={color} onChange={(e) => handleGuessChange(index, e.target.value)}>
              <option value="">Selecciona un color</option>
              {colors.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </select>
          ))}
        </div>
        <button type="submit">Enviar Adivinanza</button>
      </form>
      {message && <p>{message}</p>}
      <p>Intentos restantes: {maxAttempts - attempts}</p>
      <button onClick={resetGame}>Reiniciar Juego</button> {/* Botón para reiniciar el juego */}
    </div>
  );
};

export default Mastermind;
