import React from 'react';

const WebChat = () => {
  return (
    <div style={{ height: '50vh', width: '50%', overflow: 'hidden' }}>
      <iframe
        src="http://kiwi.mundochat.net:8080" // Cambia esta URL por la de tu KiwiIRC
        title="MundoChat"
        style={{
          height: '100%',
          width: '100%',
          border: 'none',
        }}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default WebChat;
