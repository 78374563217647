import React from 'react';
import '../App.scss'; // Asegúrate de importar el archivo CSS

const About = () => {
  return (
    <div className="about"> {/* Aplica la clase CSS aquí */}
      <h1>¿Quiénes somos?</h1>
      <p>
        En <strong>MundoChat</strong>, somos más que una simple plataforma de chat. 
        Somos una comunidad vibrante y diversa donde personas de todos los rincones se 
        reúnen para compartir, reír y disfrutar de conversaciones significativas. Nuestro 
        objetivo es crear un espacio seguro y acogedor donde cada voz sea escuchada y valorada.
      </p>
      <p>
        Desde nuestra fundación, nos hemos comprometido a fomentar un ambiente de amistad y 
        apoyo, donde los usuarios pueden conectarse con otros que comparten sus intereses. 
        Organizamos eventos regulares, juegos y actividades que permiten a los miembros 
        interactuar y fortalecer lazos.
      </p>
      <p>
        En MundoChat, valoramos la creatividad y la expresión personal. Ofrecemos diferentes 
        salas de chat temáticas donde los usuarios pueden explorar sus pasiones, compartir 
        ideas y colaborar en proyectos. Ya sea que te guste la música, los videojuegos, el 
        arte o la tecnología, aquí encontrarás un lugar donde encajar.
      </p>
      <p>
        Además, estamos comprometidos con el crecimiento y la evolución de nuestra comunidad. 
        Siempre estamos abiertos a nuevas ideas y sugerencias para mejorar la experiencia de 
        nuestros usuarios. Creemos que la retroalimentación es fundamental para seguir creciendo 
        juntos.
      </p>
      <p>
        Te invitamos a unirte a nosotros en esta aventura. ¡Bienvenido a MundoChat, donde las 
        conexiones se hacen realidad y cada día es una nueva oportunidad para crear recuerdos 
        inolvidables!
      </p>
    </div>
  );
};

export default About; // Asegúrate de que se exporta correctamente
